import axios from 'axios';
import OpenAI from "openai";

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const openai = new OpenAI({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true
});

// export const analyzeData = async (data, conditionDescription) => {
//   const formattedData = JSON.stringify(data, null, 2);

//   const messages = [
//     {
//       role: "system",
//       content: `As a biomechanics and gait analysis specialist working with a physical therapist, evaluate gait metrics summary data. Your tasks:
    
//     1. Identify ONLY the metrics deviating significantly (>40%) from clinical norms. Convert underscored names to spaced, capitalized terms.
//     2. For each identified metric, briefly explain its potential implications.
//     3. Use the following format for your response:
    
//     "Analysis of the trial below with a focus on significant deviations from healthy ranges:
//     [jump a line]
//     Summary:
//     - Metric Name: Value - Brief explanation of potential implications.
//     [jump a line]
//     Overall assessment: [One-sentence summary of findings without using alarming language]"
    
//     If no metrics deviate >40%, state: "All values are within typical ranges, suggesting no significant deviations in gait patterns."
    
//     Be concise and avoid speculation. Reference the patient's condition in your analysis when relevant.
    
//     Clinical norms (approximate):
//     - Walking Speed: >0.8 m/s
//     - Knee Flexion (Midswing): ~30°
//     - Knee Flexion (Midstance): 5-18°
//     - Stance Ratio: ~60% of gait cycle
//     - Swing Ratio: ~40% of gait cycle
//     - Cadence: ≥100 steps/min
//     - Stride Length: >0.9m
//     - Knee Flexion (Initial Swing): ~40°
//     - Knee Range of Motion: 0° to ~60°
    
//     Note: These norms are general guidelines and can vary based on factors like age, height, and weight.`
//     },
//     {
//       role: "user",
//       content: `Condition: ${conditionDescription}\n\nSummary data: ${formattedData}`
//     }
//   ];

//   const response = await openai.chat.completions.create({
//     messages: messages,
//     model: "gpt-4o-mini",
//     temperature: 0.05,
//   });
//   return response.choices[0].message.content;
// };

export const analyzeData = async (trialInfo, summaryData, conditionDescription, imageUrls) => {
  const formattedData = JSON.stringify(summaryData, null, 2);
  const formattedTrialInfo = JSON.stringify(trialInfo, null, 2);

  const systemPrompt = `
  You are a biomechanics and gait analysis specialist assisting a physical therapist in understanding the gait spatio-temporal metrics and joint angles of the patient's trial.
  Your task is to assist the physical therapist in reading and understanding the gait metrics summary data and gait metrics graphs of the patient's trial.
  - Do not hallucinate.
  - Be concise but straight to the point.
  - Don't guess the patient's condition and do not give recommendations.
  - Assist considerably in reading and understanding the data. When describing the graphs use the x axis (time) and y axis (gait related metric) to support your claims.
  - It's not necessary to make a exhaustive list of all the values. Just spot the ones that deserve your expert attention.
  `

  const messages = [
    {
      role: "system",
      content: systemPrompt,
    },
    { 
      role: "user",
      content: imageUrls 
        ? [
            { type: "text", text: "Here are the following trial info, summary data and graphs of the patient's trial. Analyze them and assist the physical therapist in understanding the intrisic characteristics of the trial." },
            { type: "text", text: `Trial info: ${formattedTrialInfo}` },
            { type: "text", text: `Summary data: ${formattedData}` },
            ...imageUrls.map(url => ({
              type: "image_url",
              image_url: { url },
            })),
          ]
        : `Condition: ${conditionDescription}\n\nSummary data: ${formattedData}`
    },
  ];

  const response = await openai.chat.completions.create({
    model: "gpt-4o",
    messages: messages,
    temperature: 0.1,
    max_tokens: 1000,
  });

  return response.choices[0].message.content;
};

