import { db } from "../firebase";
import {
    collection,
    query,
    doc,
    where,
    getDocs,
    getDoc,
    limit,
} from "firebase/firestore";
import axios from 'axios';

// Ferch user info by user ID
export const fetchUserByUserId = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
        const userData = userDoc.data();

        return {
            id: userDoc.id,
            name: userData.name,
            email: userData.email,
        }
    } else {
        console.error("No document found with the provided userId:", userId);
        return null;
    }
}


// Fetch all Trials' ID, Date, Start Time, End Time, Leg Side, Session Type
// Fetch the latest Trial's ID, Date, Start Time, End Time, Leg Side, Session Type
export const fetchTrialsByPatientId = async (patientId) => {
    const trialsQuery = query(
        collection(db, "minute_data"),
        where("patientId", "==", patientId),
        where("sessionType", "!=", "Daily Walk")
    );

    const trialsSnap = await getDocs(trialsQuery);

    let latestTrial = null;
    
    const trialsData = trialsSnap.docs.map(doc => {
        const data = doc.data();
        const currentTrial = {
            id: doc.id,
            date: data.date,
            startTimestamp: data.startTimestamp,
            endTimestamp: data.endTimestamp,
            deviceSide: data.deviceSide,
            sessionType: data.sessionType,
            clinician_notes: data.clinician_notes,
        };

        if (!latestTrial || currentTrial.startTimestamp > latestTrial.startTimestamp) {
            latestTrial = currentTrial;
        }


        return currentTrial;
    });

    return { trialsData, latestTrial };
}

export const checkIfPatientHasTrials = async (patientId) => {
    const trialsQuery = query(
        collection(db, "minute_data"),
        where("patientId", "==", patientId),
        where("sessionType", "!=", "Daily Walk"),
        limit(1) // Limit to only one document
    );

    const trialsSnap = await getDocs(trialsQuery);
   
    return trialsSnap.docs.length !== 0;
};

export const fetchTrialByTrialId = async (trialId) => {
    const trialDocRef = doc(db, "minute_data", trialId);
    const trialDoc = await getDoc(trialDocRef);

    if (trialDoc.exists()) {
        return trialDoc.data();
    } else {
        console.error("No document found with the provided trialId:", trialId);
        return null;
    }
}

export const fetchRawDataByTrialId = async (trialId) => {
    const rawDataQuery = query(
        collection(db, "raw_data"),
        where("minuteDataId", "==", trialId)
    );

    const rawDataSnap = await getDocs(rawDataQuery);
    if (!rawDataSnap.empty) {
        const firstDoc = rawDataSnap.docs[0];
        const rawData = firstDoc.data().rawData;
        if (rawData.length > 1) {
            return rawData;
        } else {
            return [];
        }
    } else {
        console.error("No raw data found with the provided trialId:", trialId);
        return [];
    }
}

export const fetchDailyDataByPatientIdAndWeek = async (patientId, startDate, endDate) => {
    const formatDateString = (date) => {
        return date.toISOString().split('T')[0];
    };
    
    const dailyDataQuery = query(
        collection(db, "minute_data"),
        where("patientId", "==", patientId),
        where("sessionType", "==", "Daily Walk"),
        where("date", ">=", formatDateString(startDate)),
        where("date", "<=", formatDateString(endDate)),
    );

    const datas = Array.from({ length: 7 }, (_, index) => ({
        date: formatDateString(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index)),
        step_count: 0,
        walking_time: 0,
        stride_length: 0,
        knee_range_motion: 0,
    }));

    const dailyDataSnap = await getDocs(dailyDataQuery);
    dailyDataSnap.docs.map(doc => {
        const docData = doc.data();
        const docDate = docData?.date;
    
        const dataForDay = datas.find(data => data.date === docDate);
        if (dataForDay) {
            dataForDay.step_count = docData?.summaryData?.step_count || 0;
            dataForDay.walking_time = typeof docData?.summaryData?.walking_time === 'number' 
                ? parseFloat(((docData.summaryData.walking_time) / 60).toFixed(2)) 
                : 0;
            dataForDay.stride_length = typeof docData?.summaryData?.stride_length === 'number' 
                ? parseFloat(docData.summaryData.stride_length.toFixed(2)) 
                : 0;
            dataForDay.knee_range_motion = typeof docData?.summaryData?.knee_range_motion === 'number' 
                ? parseFloat(docData.summaryData.knee_range_motion.toFixed(2)) 
                : 0;
        }
    });

    console.log("Fetch weekly data between: ", formatDateString(startDate), formatDateString(endDate));

    return datas;
}

// export const fetchDailyDataByPatientIdAndMonth = async (patientId, date) => {
//     const formatDateString = (date) => {
//         return date.toISOString().split('T')[0];
//     };

//     // Extract the year and month from the date parameter
//     const year = date.getFullYear();
//     const month = date.getMonth(); // January is 0, December is 11

//     // Calculate the first and last day of the month
//     const firstDayOfMonth = new Date(year, month, 1);
//     const lastDayOfMonth = new Date(year, month + 1, 0); // 0th day of the next month is the last day of the current month

//     const dailyDataQuery = query(
//         collection(db, "minute_data"),
//         where("patientId", "==", patientId),
//         where("sessionType", "==", "Daily Walk"),
//         where("date", ">=", formatDateString(firstDayOfMonth)),
//         where("date", "<=", formatDateString(lastDayOfMonth))
//     );

//     const datas = [];

//     const dailyDataSnap = await getDocs(dailyDataQuery);
//     dailyDataSnap.docs.forEach(doc => {
//         const summaryData = doc.data()?.summaryData;
//         const dataForDay = {
//             date: doc.data()?.date,
//             step_count: summaryData?.step_count ?? 0,
//             walking_time: typeof summaryData?.walking_time === 'number' ? parseFloat((summaryData.walking_time / 60).toFixed(2)) : 0,
//             stride_length: typeof summaryData?.stride_length === 'number' ? parseFloat(summaryData.stride_length.toFixed(2)) : 0,
//             knee_range_motion: typeof summaryData?.knee_range_motion === 'number' ? parseFloat(summaryData.knee_range_motion.toFixed(2)) : 0,
//         };
//         datas.push(dataForDay);
//     });

//     // console.log("Fetch monthly data between: ", formatDateString(firstDayOfMonth), formatDateString(lastDayOfMonth));

//     return datas;
// }

export const fetchDailyDataByPatientIdAndMonth = async (patientId, date) => {
    const formatDateString = (date) => {
        return date.toISOString().split('T')[0];
    };

    const generateDateRange = (firstDay, lastDay) => {
        const dates = [];
        let currentDate = new Date(firstDay);
        while (currentDate <= lastDay) {
            dates.push(formatDateString(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const year = date.getFullYear();
    const month = date.getMonth();

    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const dailyDataQuery = query(
        collection(db, "minute_data"),
        where("patientId", "==", patientId),
        where("sessionType", "==", "Daily Walk"),
        where("date", ">=", formatDateString(firstDayOfMonth)),
        where("date", "<=", formatDateString(lastDayOfMonth))
    );

    const datas = {};

    const dailyDataSnap = await getDocs(dailyDataQuery);
    dailyDataSnap.docs.forEach(doc => {
        const summaryData = doc.data()?.summaryData;
        datas[doc.data()?.date] = {
            date: doc.data()?.date,
            step_count: summaryData?.step_count ?? 0,
            walking_time: typeof summaryData?.walking_time === 'number' ? parseFloat((summaryData.walking_time / 60).toFixed(2)) : 0,
            stride_length: typeof summaryData?.stride_length === 'number' ? parseFloat(summaryData.stride_length.toFixed(2)) : 0,
            knee_range_motion: typeof summaryData?.knee_range_motion === 'number' ? parseFloat(summaryData.knee_range_motion.toFixed(2)) : 0,
        };
    });

    const dateRange = generateDateRange(firstDayOfMonth, lastDayOfMonth);
    const filledData = dateRange.map(dateString => {
        return datas[dateString] || {
            date: dateString,
            step_count: 0,
            walking_time: 0,
            stride_length: 0,
            knee_range_motion: 0,
        };
    });

    console.log("Fetch monthly data between: ", formatDateString(firstDayOfMonth), formatDateString(lastDayOfMonth));
    return filledData;
};

export const fetchDailyDataByPatientIdAndYear = async (patientId, date) => {
    const formatDateString = (date) => {
        return date.toISOString().split('T')[0];
    };

    // Extract the year from the date parameter
    const year = date.getFullYear();

    const monthlyTotals = [];

    // Iterate over each month
    for (let month = 0; month < 12; month++) {
        // Calculate the first and last day of the current month
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0); // 0th day of the next month is the last day of the current month

        const dailyDataQuery = query(
            collection(db, "minute_data"),
            where("patientId", "==", patientId),
            where("sessionType", "==", "Daily Walk"),
            where("date", ">=", formatDateString(firstDayOfMonth)),
            where("date", "<=", formatDateString(lastDayOfMonth))
        );

        const dailyDataSnap = await getDocs(dailyDataQuery);
        const monthlyDataArray = dailyDataSnap.docs.map(doc => doc.data());

        let validDays = 0;

        // Calculate totals for the month
        const totals = monthlyDataArray.reduce((acc, curr) => {
            const summaryData = curr.summaryData;
            let isDayValid = false;

            if (summaryData?.step_count > 0) {
                acc.step_count += summaryData.step_count;
                isDayValid = true;
            }
            if (typeof summaryData?.walking_time === 'number' && summaryData.walking_time > 0) {
                acc.walking_time += summaryData.walking_time / 60;
                isDayValid = true;
            }
            if (typeof summaryData?.stride_length === 'number' && summaryData.stride_length > 0) {
                acc.stride_length += summaryData.stride_length;
                isDayValid = true;
            }
            if (typeof summaryData?.knee_range_motion === 'number' && summaryData.knee_range_motion > 0) {
                acc.knee_range_motion += summaryData.knee_range_motion;
                isDayValid = true;
            }

            if (isDayValid) {
                validDays++;
            }

            return acc;
        }, { step_count: 0, walking_time: 0, stride_length: 0, knee_range_motion: 0 });

        totals.walking_time = parseFloat(totals.walking_time.toFixed(2));
        totals.stride_length = parseFloat(totals.stride_length.toFixed(2));
        totals.knee_range_motion = parseFloat(totals.knee_range_motion.toFixed(2));

        // Add the totals for this month to the array
        monthlyTotals.push({ ...totals, valid_days: validDays });
    }

    console.log("Fetch yearly data for year: ", year);
    return monthlyTotals;
}

export const fetchDailyDataByPatientIdAndCustomRange = async (patientId, startDate, endDate) => {
    const formatDateString = (date) => {
        return date.toISOString().split('T')[0];
    };

    const dailyDataQuery = query(
        collection(db, "minute_data"),
        where("patientId", "==", patientId),
        where("sessionType", "==", "Daily Walk"),
        where("date", ">=", formatDateString(startDate)),
        where("date", "<=", formatDateString(endDate))
    );

    const datas = [];

    const dailyDataSnap = await getDocs(dailyDataQuery);
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const dateString = formatDateString(currentDate);
        const docForDate = dailyDataSnap.docs.find(doc => doc.data()?.date === dateString);
        const summaryData = docForDate?.data()?.summaryData;

        const dataForDay = {
            date: dateString,
            step_count: summaryData?.step_count ?? 0,
            walking_time: typeof summaryData?.walking_time === 'number' ? parseFloat((summaryData.walking_time / 60).toFixed(2)) : 0,
            stride_length: typeof summaryData?.stride_length === 'number' ? parseFloat(summaryData.stride_length.toFixed(2)) : 0,
            knee_range_motion: typeof summaryData?.knee_range_motion === 'number' ? parseFloat(summaryData.knee_range_motion.toFixed(2)) : 0,
        };

        datas.push(dataForDay);

        // Increment the date by one day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    console.log("Fetch custom data between: ", formatDateString(startDate), formatDateString(endDate));
    console.log(datas);
    return datas;
}

export const calculateAverageMetrics = (data = [], view) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.warn('Data is not an array or is empty:', data);
    return {
      averageStepCount: 0,
      averageWalkingTime: 0,
      averageStrideLength: 0,
      averageKneeRangeMotion: 0,
    };
  }

  let sumStepCount = 0, sumWalkingTime = 0, sumStrideLength = 0, sumKneeRangeMotion = 0;
  let count = 0;

  if (view === 'year') {
    data.forEach(month => {
      if (month.valid_days > 0) {
        sumStepCount += month.step_count;
        sumWalkingTime += month.minutes;
        sumStrideLength += month.step_length * month.valid_days;
        sumKneeRangeMotion += month.romKnee * month.valid_days;
        count += month.valid_days;
      }
    });
  } else {
    data.forEach(item => {
      if (item.step_count !== 0 || item.minutes !== 0 || item.step_length !== 0 || item.romKnee !== 0) {
        sumStepCount += item.step_count;
        sumWalkingTime += item.minutes;
        sumStrideLength += item.step_length;
        sumKneeRangeMotion += item.romKnee;
        count++;
      }
    });
  }

  if (count === 0) {
    return { averageStepCount: 0, averageWalkingTime: 0, averageStrideLength: 0, averageKneeRangeMotion: 0 };
  }

  return {
    averageStepCount: Math.round(sumStepCount / count),
    averageWalkingTime: Math.round(sumWalkingTime / count),
    averageStrideLength: parseFloat((sumStrideLength / count).toFixed(2)),
    averageKneeRangeMotion: parseFloat((sumKneeRangeMotion / count).toFixed(2)),
  };
};

export const getTotalStepCounts = (data) => {
    const stepCounts = data.map((d) => d.step_count);
    return stepCounts.reduce((a, b) => a + b, 0);
}

export const getTotalValidDays = (data, view) => {
    if (view === "year") {
        return data.reduce((acc, curr) => acc + curr.valid_days, 0);
    }
    return data.filter(d => d.step_count > 0).length;
}

// export const getTestforAxios = async () => {
//     const response = await axios.get('http://52.202.203.228:5000/patient_info/1');
//     return response.data;
// }

export const getTestforAxios = async () => {
    const response = await axios.get('http://52.202.203.228:5000/user_info/1');
    return response.data;
}

