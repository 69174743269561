import React, { useState } from 'react';
import { 
  Box, Typography, IconButton, CircularProgress, 
  TextField, Button, Dialog, DialogActions, 
  DialogContent, DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { styled } from "@mui/material/styles";
import ReactMarkdown from 'react-markdown';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  color: '#5a5a5a',
  display: 'inline-block',
  marginRight: theme.spacing(2),
}));

const StyledMarkdown = styled(ReactMarkdown)`
  & p {
    margin: 0.5em 0;
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: 1rem;
    line-height: 1.5;
  }
  & strong {
    font-weight: 600;
  }
  & ul {
    margin: 0.5em 0;
    padding-left: 1.5em;
  }
  & li {
    margin: 0.25em 0;
  }
`;

const AIAnalysisBox = ({ isAIAnalysis, setIsAIAnalysis, aiAnalysisResult, loading, error }) => {
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(true);

  if (!isAIAnalysis) return null;

  const handleThumbUp = () => {
    setIsPositiveFeedback(true);
    setFeedbackDialogOpen(true);
  };

  const handleThumbDown = () => {
    setIsPositiveFeedback(false);
    setFeedbackDialogOpen(true);
  };

  const handleFeedbackSubmit = () => {
    console.log(`${isPositiveFeedback ? 'Positive' : 'Negative'} feedback received:`, feedbackText);
    setFeedbackDialogOpen(false);
    setFeedbackText('');
  };

  return (
    <Box sx={{ p: 2, border: '1px solid #E0E0E0', borderRadius: '4px', backgroundColor: '#FAFAFA', marginY: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <StyledTypography variant="h6" color="secondary">AI Analysis (Beta)</StyledTypography>
        <Box>
          <IconButton onClick={handleThumbUp} color="primary">
            <ThumbUpIcon />
          </IconButton>
          <IconButton onClick={handleThumbDown} color="primary">
            <ThumbDownIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={() => setIsAIAnalysis(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box pl={2} pr={2} pb={2}>
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}
        {aiAnalysisResult && (
          <StyledMarkdown>{aiAnalysisResult}</StyledMarkdown>
        )}
      </Box>

      <Dialog open={feedbackDialogOpen} onClose={() => setFeedbackDialogOpen(false)}>
        <DialogTitle>{isPositiveFeedback ? "Provide Positive Feedback" : "Provide Feedback for Improvement"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label={isPositiveFeedback ? "What did you like?" : "What could be improved?"}
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleFeedbackSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AIAnalysisBox;