import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDashboardContext } from "../../contexts/DashboardContext";
import { useState } from "react";
import TrialGrid from './TrialGrid';
import TrialPicker from './TrialPicker';
import Metrics from './Metrics';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchTrialByTrialId } from '../../utils/dataFetcher';
import { useEffect, useRef } from 'react';
import { formatTrialDate } from '../../utils/timeUtils';
import OneTapButton from './OneTapButton';
import TextField from '@mui/material/TextField';
import { Button, Modal } from "@mui/material";
import { Modal as BaseModal } from '@mui/base/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PDFDocument from './PDFDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CompareTable from './CompareTable';
import Chip from '@mui/material/Chip';
import MultiSelector from "../../components/MultiSelector";
import { getTrialByTrialId, getSummaryDataByTrialId } from '../../utils/fastAPI'

const CompareTrials = () => {
    const { trialId1, trialId2, patientId } = useParams();
    const navigate = useNavigate();
    const { latestTrial, patientName, patientInfo, latestTrialId } = useDashboardContext();
    const [selectedTrialInfo1, setSelectedTrialInfo1] = useState(latestTrial);
    const [selectedTrialInfo2, setSelectedTrialInfo2] = useState(latestTrial);
    const [summaryData1, setSummaryData1] = useState({});
    const [summaryData2, setSummaryData2] = useState({});
    const [currentTrialId1, setCurrentTrialId1] = useState(trialId1);
    const [currentTrialId2, setCurrentTrialId2] = useState(trialId2);
    const [pdfCharts, setPdfCharts] = useState([]);
    const [compareChart, setCompareChart] = useState(null);
    const [open, setOpen] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMetrics, setSelectedMetrics] = useState(["Gait Cycle", "Knee Flexion", "Hip Flexion", "Ankle Speed", "Walking Probability"]);

    const handleOpen = () => {
        if (!latestTrialId) {
            return;
        }
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const ModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        bgcolor: 'background.paper',
        border: '1px solid #F5F5F5',
        boxShadow: 24,
        p: 3,
        borderRadius: 2,
    };

    useEffect(() => {
        if (!latestTrialId) {
            return;
        }

        const fetchTrialData1 = async () => {
            if (currentTrialId1) {
                setIsLoading(true);
                setIsLoading1(true);
                const trailData = await getTrialByTrialId(currentTrialId1);
                setSelectedTrialInfo1(trailData);
                setIsLoading(false);
                setIsLoading1(false);
            }
        }
        const fetchSummaryData1 = async () => {
            if (currentTrialId1) {
                setIsLoading(true);
                setIsLoading1(true);
                const summaryData = await getSummaryDataByTrialId(currentTrialId1);
                setSummaryData1(summaryData);
                setIsLoading(false);
                setIsLoading1(false);
            }
        }
        navigate(`/dashboard/${patientId}/compare/${currentTrialId1}/vs/${currentTrialId2}`);
        fetchTrialData1();
        fetchSummaryData1();
    }, [currentTrialId1]);

    useEffect(() => {
        if (!latestTrialId) {
            return;
        }

        const fetchTrialData2 = async () => {
            if (currentTrialId2) {
                setIsLoading(true);
                setIsLoading2(true);
                const trailData = await getTrialByTrialId(currentTrialId2);
                setSelectedTrialInfo2(trailData);
                setIsLoading(false);
                setIsLoading2(false);
            }
        }
        const fetchSummaryData2 = async () => {
            if (currentTrialId2) {
                setIsLoading(true);
                setIsLoading2(true);
                const summaryData = await getSummaryDataByTrialId(currentTrialId2);
                setSummaryData2(summaryData);
                setIsLoading(false);
                setIsLoading2(false);
            }
        }
        navigate(`/dashboard/${patientId}/compare/${currentTrialId1}/vs/${currentTrialId2}`);
        fetchTrialData2();
        fetchSummaryData2();
    }, [currentTrialId2]);


    const [selectedDate1, setSelectedDate1] = useState(() => {
        return formatTrialDate(selectedTrialInfo1?.date);
    });

    const [selectedDate2, setSelectedDate2] = useState(() => {
        return formatTrialDate(selectedTrialInfo2?.date);
    });

    useEffect(() => {
        setSelectedDate1(formatTrialDate(selectedTrialInfo1?.date));
    }, [selectedTrialInfo1]);

    useEffect(() => {
        setSelectedDate2(formatTrialDate(selectedTrialInfo2?.date));
    }, [selectedTrialInfo2]);

    const StyledTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        color: '#5a5a5a',
        display: 'inline-block',
        marginRight: theme.spacing(2),
    }));

    const compareChartRef = useRef();

    const captureChart = (chartRef) => {
        if (chartRef.current) {
            const echartsInstance = chartRef.current.getEchartsInstance();
            echartsInstance.setOption({
                xAxis: { name: false },
                yAxis: { name: false },
                dataZoom: { show: false },
                toolbox: { show: false }
            });

            // Capture the chart
            const url = echartsInstance.getDataURL({
                pixelRatio: 4,
                backgroundColor: '#fff'
            });

            // echartsInstance.setOption({
            //     xAxis: { name: true },
            //     yAxis: { name: true },
            //     dataZoom: { show: true },
            //     toolbox: { show: true }
            // });

            return url;
        }
    };

    const captureCompareChart = async () => {
        const chartImage = captureChart(compareChartRef);
        return chartImage;
    };

    const generateDefaultReportName = (patientName) => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `Report_${patientName}_${year}_${month}_${day}`;
    }

    useEffect(() => {
        async function fetchCompareChart() {
            const chartImage = await captureCompareChart();
            setCompareChart(chartImage);
        }
        setTimeout(() => {
            fetchCompareChart();
        }, 3000);
    }, [selectedTrialInfo1, selectedTrialInfo2]);

    const [reportName, setReportName] = useState(() => {
        return generateDefaultReportName(patientName);
    });
    const handleInputChange = (event) => {
        setReportName(event.target.value);
    };

    const handleReportTableContent = (summaryData1, summaryData2) => {
        if (summaryData1 && summaryData2) {
            return [
                {
                    metric: 'Steps',
                    trial1: summaryData1.step_count,
                    trial2: summaryData2.step_count,
                    difference: summaryData1.step_count - summaryData2.step_count,
                },
                {
                    metric: 'Walking Time',
                    trial1: summaryData1.walking_time,
                    trial2: summaryData2.walking_time,
                    difference: summaryData1.walking_time - summaryData2.walking_time,
                },
                {
                    metric: 'Speed',
                    trial1: summaryData1.speed,
                    trial2: summaryData2.speed,
                    difference: summaryData1.speed - summaryData2.speed,
                },
                {
                    metric: 'Cadence',
                    trial1: summaryData1.cadence,
                    trial2: summaryData2.cadence,
                    difference: summaryData1.cadence - summaryData2.cadence,
                },
                {
                    metric: 'Stride Length',
                    trial1: summaryData1.step_length,
                    trial2: summaryData2.step_length,
                    difference: summaryData1.step_length - summaryData2.step_length,
                },
                {
                    metric: 'Swing %',
                    trial1: summaryData1.swingRatio,
                    trial2: summaryData2.swingRatio,
                    difference: summaryData1.swingRatio - summaryData2.swingRatio,
                },
                {
                    metric: 'Stance %',
                    trial1: summaryData1.stanceRatio,
                    trial2: summaryData2.stanceRatio,
                    difference: summaryData1.stanceRatio - summaryData2.stanceRatio,
                },
                {
                    metric: 'Knee Flex° Mid Stanc',
                    trial1: summaryData1.midstanceKnee,
                    trial2: summaryData2.midstanceKnee,
                    difference: summaryData1.midstanceKnee - summaryData2.midstanceKnee,
                },
                {
                    metric: 'Knee Flex° Initial Swing',
                    trial1: summaryData1.initialswingKnee,
                    trial2: summaryData2.initialswingKnee,
                    difference: summaryData1.initialswingKnee - summaryData2.initialswingKnee,
                },
                {
                    metric: 'Knee Flex° Mid Swing',
                    trial1: summaryData1.midswingKnee,
                    trial2: summaryData2.midswingKnee,
                    difference: summaryData1.midswingKnee - summaryData2.midswingKnee,
                },
                {
                    metric: 'Total Knee ROM°',
                    trial1: summaryData1.romKnee,
                    trial2: summaryData2.romKnee,
                    difference: summaryData1.romKnee - summaryData2.romKnee,
                },
                {
                    metric: 'Hip Flex° Mid Stance',
                    trial1: summaryData1.midstanceHip,
                    trial2: summaryData2.midstanceHip,
                    difference: summaryData1.midstanceHip - summaryData2.midstanceHip,
                },
                {
                    metric: 'Hip Flex° Term Stance',
                    trial1: summaryData1.terminalstanceHip,
                    trial2: summaryData2.terminalstanceHip,
                    difference: summaryData1.terminalstanceHip - summaryData2.terminalstanceHip,
                },
                // {
                //     metric: 'Foot Drag',
                //     trial1: summaryData1.footdrag_count,
                //     trial2: summaryData2.footdrag_count,
                //     difference: summaryData1.footdrag_count - summaryData2.footdrag_count,
                // },
            ];
        }
    }

    return (
        <Box display="flex" flexDirection="column" m={1}>
            {/* <StyledTypography variant="h6">One Tap Functions</StyledTypography> */}
            <Box display="flex">
                <OneTapButton title="Select latest clinical trial" subtitle="Reset trial to latest trial" />
                <OneTapButton title="Analyze my data" subtitle="Analyze trial based on summary and metrics data" />
                <OneTapButton title="Generate Report" subtitle="Create a report to compare the two trials" onClick={handleOpen} />
                <Modal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={open}
                >
                    <Box sx={{ ...ModalStyle, position: 'relative' }} display="flex" flexDirection="column" gap={2}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <StyledTypography variant="h7">choose metrics:</StyledTypography>
                        <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
                            <MultiSelector
                                selectedMetrics={selectedMetrics}
                                onChange={setSelectedMetrics}
                            />
                        </Box>
                        <StyledTypography variant="h7">rename report:</StyledTypography>
                        <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
                            <TextField
                                id="outlined-basic"
                                value={reportName}
                                onChange={handleInputChange}
                                fullWidth
                                borderRadius={2}
                                aria-label="none"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '& fieldset': { border: 'none' },
                                    },
                                }}
                            />
                        </Box>
                        <Box display="flex" gap={2}>
                            <Button variant="outlined">
                                <PDFDownloadLink document={<PDFDocument trialInfo1={selectedTrialInfo1} trialInfo2={selectedTrialInfo2} tableContent={handleReportTableContent(summaryData1, summaryData2)} patientInfo={patientInfo} pdfCharts={pdfCharts} summaryData1={summaryData1} summaryData2={summaryData2} />} fileName={`${reportName}.pdf`}>
                                    {({ blob, url, loading, error }) => (loading ? 'Generating document...' : 'Download document')}
                                </PDFDownloadLink>
                            </Button>
                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        </Box>
                    </Box>
                </Modal>
            </Box >
            <StyledTypography variant="h6" mb={2}>Select Trials</StyledTypography>
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" mb={2}>
                <Box display="flex" flexDirection="column" flexBasis="40%" height="100%">
                    <Box display="flex" mb={1} alignItems="center" style={{ height: '40px' }}>
                        <StyledTypography variant="h7" component="div">Trial 1</StyledTypography>
                        {currentTrialId1 === latestTrialId && (
                            <Chip label="LATEST TRIAL" sx={{ fontWeight: 'bold', ml: 1 }} />
                        )}
                    </Box>
                    <Box mb={2}>
                        <TrialPicker
                            setCurrentTrialId={setCurrentTrialId1}
                            selectedDate={selectedDate1}
                            setSelectedDate={setSelectedDate1}
                            currentTrialId={currentTrialId1}
                            width={450}
                        />
                    </Box>
                    <TrialGrid selectedTrialInfo={selectedTrialInfo1} isLoading={isLoading1} summaryData={summaryData1} />
                </Box>
                <Box display="flex" flexDirection="column" flexBasis="40%" height="100%">
                    <Box display="flex" mb={1} alignItems="center" style={{ height: '40px' }}>
                        <StyledTypography variant="h7">Trial 2</StyledTypography>
                        {currentTrialId2 === latestTrialId && (
                            <Chip label="LATEST TRIAL" sx={{ fontWeight: 'bold', ml: 1 }} />
                        )}
                    </Box>
                    <Box mb={2}>
                        <TrialPicker
                            setCurrentTrialId={setCurrentTrialId2}
                            selectedDate={selectedDate2}
                            setSelectedDate={setSelectedDate2}
                            currentTrialId={currentTrialId2}
                            width={450}
                        />
                    </Box>
                    <TrialGrid selectedTrialInfo={selectedTrialInfo2} isLoading={isLoading2} summaryData={summaryData2} />
                </Box>
            </Box>
            {/* Compare Table */}
            <StyledTypography variant="h6">Difference Table</StyledTypography>
            <Box display="flex" justifyContent="center" mb={2}>
                <CompareTable summaryData1={summaryData1} summaryData2={summaryData2} loading={isLoading} />
            </Box>
            {/* Select Metrics */}
            <Box display="flex" flexDirection="column" width="100%" mb={2}>
                <StyledTypography variant="h6">Select Metrics</StyledTypography>
                <Metrics trialId1={trialId1} trialId2={trialId2} selectedMetrics={selectedMetrics} setSelectedMetrics={setSelectedMetrics} setPdfCharts={setPdfCharts} />
            </Box>
            {/* Summary Metrics */}
            {/* <Typography variant="h6" mb={2}>Summary Metrics</Typography>
            <Box display="flex" justifyContent="center">
                <CompareChart trialData1={selectedTrialInfo1?.summaryData} trialData2={selectedTrialInfo2?.summaryData} ref={compareChartRef} />
            </Box> */}
            {/* <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
            >
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Typography>Dowload page in</Typography>
                </Box>
                <CustomizedButton variant="text">PDF</CustomizedButton>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CustomizedButton variant="text">CSV</CustomizedButton>
            </Box> */}
        </Box >
    );
};

export default CompareTrials;